import React, { useEffect, useState } from 'react';

export interface IErrorFallbackProperties {
    error: any
}

const ErrorFallback: React.FC<IErrorFallbackProperties> = ({ error }) => {
    let timeOut: NodeJS.Timeout

    const [reloading, setReloading] = useState(true)

    useEffect(() => {
        timeOut = setTimeout(() => window.location.reload(), 10000);
        return () => {
            clearTimeout(timeOut)
        }
    }, [])

    return <div
        role="alert"
        style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        }}>
        <img src={'/img/sequorplatform.gif'} width={218} height={67} />
        <div>
            <h1>Sorry.</h1>
            We had a problem with the platform.<br />
            <br />
            <pre>{(error?.message ?? "")}</pre>
            <br />
            {
                reloading && <span>Reloading in 10 seconds...</span>
            }
        </div>
        <div>
            <br />
            <button onClick={() => {
                if (reloading) {
                    clearTimeout(timeOut)
                    setReloading(false)
                }
                else {
                    window.location.reload()
                }
            }}>{reloading ? 'Abort' : 'Reload'} </button>
        </div>
    </div>
}

export default ErrorFallback
