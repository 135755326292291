import { Card, CardActionArea, CardContent, Divider, Typography, makeStyles } from '@material-ui/core';
import { useGetCurrentTheme } from '@sequor/helius-appearance';
import { ITenantShort, useGetTenantConfigurations, useSetTenant } from '@sequor/tenants';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import MainBar from './MainBar';

const useStyles = makeStyles(() => ({
    card: {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        borderRadius: '2px',
        height: '250px',
        width: '485px',
        boxShadow: '0px 0px 6px 3px rgba(0, 0, 0, 0.06)'
    }
}))

const LocationIcon = () => <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="68" height="68" rx="4" fill="var(--color-icon-500)" />
    <path d="M33.5 18C30.1848 18 27.0054 19.2811 24.6612 21.5616C22.317 23.842 21 26.935 21 30.16C21 30.439 21 30.7179 21 30.9805V31.0462C21.5061 37.7087 27.191 43.0667 33.5 50C40.0874 42.7631 46 37.2821 46 30.16C46 26.935 44.683 23.842 42.3388 21.5616C39.9946 19.2811 36.8152 18 33.5 18ZM33.5 24.3179C34.6898 24.3179 35.8528 24.6613 36.842 25.3045C37.8311 25.9477 38.6019 26.8618 39.0569 27.9313C39.5118 29.0008 39.6304 30.1775 39.3977 31.3126C39.1649 32.4476 38.5913 33.49 37.7494 34.3079C36.9075 35.1257 35.8352 35.6822 34.6681 35.907C33.5009 36.1319 32.2915 36.0148 31.1928 35.5708C30.094 35.1267 29.1554 34.3756 28.4956 33.4125C27.8358 32.4493 27.4845 31.3174 27.4862 30.16C27.4884 28.6098 28.123 27.1239 29.2506 26.0285C30.3781 24.9332 31.9065 24.3179 33.5 24.3179Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const Option: React.FC<{ tenant: ITenantShort }> = ({ tenant }) => {

    const classes = useStyles()

    const setTenant = useSetTenant()

    const getDescription = () => {
        const { description } = tenant

        return description.split('\r\n').map(x => <Typography component="span" gutterBottom variant="subtitle2" style={{ color: "#909090" }}>
            {x.trim()}
        </Typography>)
    }

    return <Card className={classes.card}>
        <CardActionArea style={{ height: "100%" }} onClick={() => setTenant(tenant.name)} >
            <CardContent style={{ height: "100%" }} >
                <div style={{ display: 'flex', width: "100%", alignItems: "center", height: "45%", marginInline: 25 }}>
                    <LocationIcon />
                    <Typography gutterBottom variant="h5" color={"primary"} style={{ marginLeft: 20 }}>
                        {tenant.description}
                    </Typography>
                </div>
                <div style={{ height: "2%", width: "50%", marginInline: 25 }}>
                    <Divider />
                </div>
                <div style={{ height: "35%", display: 'flex', flexDirection: 'column', justifyContent: "center", marginInline: 25, width: "100%" }}>
                    {getDescription()}
                </div>
                <div style={{ height: "10%", width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <ArrowRightAltIcon color='primary' />
                </div>
            </CardContent>
        </CardActionArea>
    </Card>
}


const TenantChoice: React.FC = () => {

    const currentPlatformTheme = useGetCurrentTheme()

    const { tenants } = useGetTenantConfigurations()

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }}>
        <div style={{ height: 56 }}>
            <MainBar />
        </div>
        <div style={{ flexGrow: 1 }}>
            <div style={{ height: "100%" }}>
                <div style={{ height: "30%", display: 'flex', flexDirection: 'column', justifyContent: "center", paddingInline: 90 }}>
                    <Typography variant="h4" gutterBottom >
                        Bem vindo(a)
                    </Typography>
                    <Typography gutterBottom variant="h5" >
                        Selecione o Tenant desejado:
                    </Typography>
                    <div style={{ width: "30%", paddingTop: 20, paddingBottom: 20 }}>
                        <Divider style={{ backgroundColor: currentPlatformTheme.theme.palette.primary.main }} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    height: "70%",
                    justifyContent: 'center',
                    paddingInline: 90,
                    flexWrap: 'wrap'
                }}>
                    {tenants.map(x => <div key={x.id} style={{ margin: 10 }}>
                        <Option tenant={x} />
                    </div>
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default TenantChoice
