import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import { useGetCurrentTheme, useMakeMediaTheme } from '@sequor/helius-appearance';

const useStyles = makeStyles((theme: any) => ({
    menuButton: {
        marginRight: theme.spacing(0)
    }
}));

const MainBarIcon: React.FC = () => {
    const classes = useStyles()

    const makeMediaTheme = useMakeMediaTheme()

    const currentPlatformTheme = useGetCurrentTheme()

    const { mainBar } = currentPlatformTheme.elements

    return <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={() => { }}>
        {makeMediaTheme(mainBar?.icon)}
    </IconButton>
}

export default MainBarIcon