import React from 'react';
import info from './info.json'

const ViewVersion: React.FC = () => {

    return <div>
        {info.name}@{info.version}
    </div>
}

export default ViewVersion