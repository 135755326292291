import { makeStyles } from '@material-ui/styles';
import { mergeObjects } from '@sequor/helpers';
import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import MainBarIcon from './MainBarIcon';
import { IPlatformTheme, useGetCurrentTheme } from '@sequor/helius-appearance';

const useStyles = makeStyles(() => {

  const getMainBar = (currentPlatformTheme: IPlatformTheme) => {

    const { mainBar } = currentPlatformTheme.elements

    const mainBarStyle = mergeObjects({
      justifyContent: 'space-between',
      height: 56
    }, mainBar?.style)

    return mainBarStyle
  }

  return {
    root: {
      flexGrow: 1,
    },
    line: {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      height: 56
    },
    mainBar: getMainBar
  }
})

const MainBar: React.FC = () => {

  const currentPlatformTheme = useGetCurrentTheme()

  const classes = useStyles(currentPlatformTheme)

  const { mainBar } = currentPlatformTheme.elements

  const getTitle = () => (mainBar?.title ?? "").trim()

  return <div className={classes.root}>
    <AppBar position="static">
      <Toolbar variant="dense" className={classes.mainBar} >
        <div className={classes.line}>
          <MainBarIcon />
          <Typography variant="h6" color="inherit" noWrap>
            {getTitle()}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  </div>
}

export default MainBar
