import { PlatformLoadingView } from '@sequor/loading-screen';
import { TenantStatus, useGetTenantStatus } from '@sequor/tenants';
import React, { Suspense } from 'react';
import { Redirect } from 'react-router';

const SecurityRoot = React.lazy(() => import('@sequor/spa-security/dist/SecurityRoot'))

const SecurityStartup: React.FC = () => {
    const tenantStatus = useGetTenantStatus()

    return <React.Fragment>
        {tenantStatus === TenantStatus.Selected ?
            <Suspense fallback={<PlatformLoadingView />}>
                <SecurityRoot />
            </Suspense>
            : <Redirect to={{ pathname: '/tenants' }} />}
    </React.Fragment>
}

export default SecurityStartup