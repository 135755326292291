import { PlatformLoadingView } from '@sequor/loading-screen';
import React, { Suspense, useEffect, useState } from 'react';
import { useGetSecurityStatus, SecurityStatus } from '@sequor/security'
import { useGetCurrentI18n } from '@sequor/internationalization'
import { Redirect } from 'react-router-dom';
import { LoadingOverlayContextProvider } from '@sequor/helius-loading-overlay';
import { DialogContextProvider } from '@sequor/helius-dialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { SectionContextProvider } from '@sequor/helius-section'
import { TenantStatus, useGetTenantStatus } from '@sequor/tenants';
import Apps from '../apps/Index'

const MainRoot = React.lazy(() => import('@sequor/spa-main/dist/MainRoot'))

const MainStartup: React.FC = () => {

    const currentLocale = useGetCurrentI18n()

    const status = useGetSecurityStatus()

    const [state, setState] = useState(<PlatformLoadingView />)

    const tenantStatus = useGetTenantStatus()

    useEffect(() => {
        if (tenantStatus === TenantStatus.Pending)
            setState(<Redirect to={{ pathname: '/tenants' }} />)
    }, [])

    useEffect(() => {
        if (status === SecurityStatus.Authenticated) {
            setState(<Suspense fallback={<PlatformLoadingView />}>
                <SectionContextProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DialogContextProvider
                            i18n={currentLocale.entries as any}>
                            <LoadingOverlayContextProvider>
                                <MainRoot moduleConfigurationsLoad={Apps as any} />
                            </LoadingOverlayContextProvider>
                        </DialogContextProvider>
                    </MuiPickersUtilsProvider>
                </SectionContextProvider>
            </Suspense>)
        }
    }, [status, currentLocale])

    return <React.Fragment>
        {state}
    </React.Fragment>
}

export default MainStartup

